import React from 'react';

const USAIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1235"
      height="650"
      viewBox="0 0 7410 3900"
    >
      <rect width="7410" height="3900" fill="#b22234" />
      <path
        d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0"
        stroke="#fff"
        strokeWidth="300"
      />
      <rect width="2964" height="2100" fill="#3c3b6e" />
      <g fill="#fff">
        <g id="s18">
          <g id="s9">
            <g id="s5">
              <g id="s4">
                <path
                  id="s"
                  d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"
                />
                <use y="420" />
                <use y="840" />
                <use y="1260" />
              </g>
              <use y="1680" />
            </g>
            <use x="247" y="210" />
          </g>
          <use x="494" />
        </g>
        <use x="988" />
        <use x="1976" />
        <use x="2470" />
      </g>
    </svg>
  );
};

export default USAIcon;
